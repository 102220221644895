<div>
    <login-home-navigation></login-home-navigation>
	<p>&nbsp;</p>
</div>

<section class="sptb.sec2 innerpage-margin " style="background-color:#fff;margin-top: 50px;" >
    <div class="container">
        <div class="row">
           
            <div class="col-lg-6 col-md-6 col-sm-6 padding-right-0 ">
                <a href="/buyers-benefits" ><img src="../../../assets/images/media/buyer.png" width="100%" height="100%" title="buyer" ></a>
                  
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 padding-0">
       
                <a href="/vendors-benefits" ><img src="../../../assets/images/media/vendor.png" width="100%" height="100%" title="vendor" ></a>
            </div>
            
        </div>
    </div>
    
            
    </section>



<section class="sptb innerpage-margin" style="background-color:#fff;" >
    <div class="container">
      <div class="card">
        <a href="/buyers-benefits" ><img src="../../../assets/images/media/buyer.webp" width="100%" height="100%" title="buyer" ></a>
        
    
    
        <a href="/buyers-benefits" ><img src="../../../assets/images/media/buyerben.webp" width="100%" height="100%" title="buyerbenefit"></a>
    </div>
    <div class="section2-body">
        <p>If you are actively seeking a solution to last-minute supplier demands and aiming to enhance work efficiency by saving time in finding the right supplier, then you are at the right place .</p>
    </div>
    </div>       

    <div class="container">
  
        <div class= "row col-lg-12 col-md-12 col-sm-12" style=" text-align: center ;margin-top: 2%;">
          
            <div id="g_id_onload"
            data-client_id="152150661777-mn37jrk3u8s701hcrhoo7mt5lj52a43h.apps.googleusercontent.com"
            data-context="signin"
            data-ux_mode="popup"
            data-login_uri="https://developmentapiv2.getyourvendor.com/public/api/registerapi"
            data-auto_select="true"
            data-itp_support="true">
       </div>
       
       <div class="g_id_signin"
            data-type="standard"
            data-shape="pill"
            data-theme="filled_blue"
            data-text="signin_with"
            data-size="large"
            data-logo_alignment="left">
       </div>
        </div>
      </div>
</section>


<section class="sptb.sec2 innerpage-margin " style="background-color:#fff;" >
<div class="container">
    <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-7">
   
        <a href="login/signup" > <video loop width="100%" height="100%"  controls autoplay muted>
            <source src="../../../assets/images/media/section2.mp4" type="video/mp4" title="Overview">
         </video></a>
        </div>
        <div class="col-lg-7 col-md-7 col-sm-7 section2-body">
            <h3 style="text-transform:uppercase ;"><b>Challenges commonly encountered by buyers in the sourcing process</b></h3>
                <p class="fa fa-hand-o-right"> Unreliable suppliers .</p><br>
                <p class="fa fa-hand-o-right"> Lack of visibility into a supplier's operations.</p><br>
                <p class="fa fa-hand-o-right"> Adequate resources and budget.</p><br>
                <p class="fa fa-hand-o-right"> Price negotiation.</p> <br>
                <p class="fa fa-hand-o-right"> Quality control.</p> <br>
                <p class="fa fa-hand-o-right"> Compliance with regulations.</p> <br>
                <p class="fa fa-hand-o-right"> Overreliance on a single supplier.</p> <br>
                <p class="fa fa-hand-o-right"> Communication barriers.</p>
              
              
        </div>
        
        <p class="section2-body">Book Live demo to find out we are solving above challenges in a effective way <a href="login/demo-signup" ><button class="ovalbtn"><b>BOOK LIVE DEMO</b></button></a></p>
    </div>
      </div>

        
</section>
<section class="sptb innerpage-margin" style="background-color:#fff;" >
    <div class="container">
        <div class="card">
          <a href="/vendors-benefits" ><img src="../../../assets/images/media/newyear.webp" width="100%" height="100%" title="vendor" ></a>
          
      
      
         
      </div>
      </div>
</section>
<section class="sptb innerpage-margin " style="background-color:#fff" >

    <div class="container">
    <div class="row">
       
        <div class="col-lg-6 col-md-6 col-sm-6 section2-body">
            <p>We are delighted to have effectively assisted customers across India and internationally in connecting with their ideal buyers. Additionally, we are pleased to have secured orders of various sizes with a success rate of 99%.</p>
            <p><img src="assets/images/media/home/flow.webp" title="vendor process"></p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
            <a href="/vendors-benefits"> <img src="assets/images/media/home/map.webp" title="wins" alt="wins" width="auto" height="auto"></a>
            
        </div>
        
       

    </div>
    </div>

</section>
    
<section class="sptb innerpage-margin " style="background-color:#fff">
    <div class="container">
        <div>
         <a href="/customerwins"><img src="assets/images/media/home/customerwins/success_stories.webp" title="Success Stories" alt="success" width="auto" height="auto"></a>
        </div>
        
    </div>

</section>




<section class="sptb innerpage-margin " style="background-color:#fff" >

    <div class="container">
       
        <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-5 section2-body">
            
           
            <p> At GetYourVendor, we understand that custom manufacturing is key to a strong economy, which is why we're committed to delivering the best services possible. And with our platform, Indian and global manufacturers can connect with each other in the most economical way possible, increasing their profit margins and driving success.</p><p> Don't wait - sign up for GetYourVendor now and take the first step towards a brighter future!

            </p>
            <p style="align-items: center;margin-top: 2%"><a href="login/demo-signup" ><button class="ovalbtn"><b>BOOK LIVE DEMO</b></button></a> </p>
        </div>
       
        <div class="col-lg-7 col-md-7 col-sm-7 ">
           <a href="/vendor-benefits"> <img src="assets/images/media/home/buyer2.webp" title="buyer benefits" alt="buyer benefits" width="100%" height="auto"></a>
          
        </div>
       
    </div> 
    </div>
  
    </section>
    <section class="sptb innerpage-margin " style="background-color:#fff">
        <div class="container">
            <img src="assets/images/media/home/stats.webp" title="stats" alt="stats" width="auto" height="auto">
        </div>

    </section>



   
    <section class="sptb innerpage-margin sec5" style="background-color:#fff;" >
     
     <div class="container">
         <div class="row">
       
        <div class="col-md-6 col-sm-6 col-lg-6 " >
         <a href="/vendors-benefits"><img src="assets/images/media/home/vendorsection.webp" alt="vendor benefits" width="100%" height="auto" title="vendor" ></a>
         
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 section2-body">
            <h2 style="text-align: left;padding-bottom: 3%;">VENDOR'S BENEFIT'S</h2>
            <p>&#10004;&nbsp; Gain unlimited RFQ access without any Vendor Code.</p>
            <p>&#10004;&nbsp; Gain global buyers visibility.</p>
            <p>&#10004;&nbsp; Save sales and marketing expenses.</p>
            <p>&#10004;&nbsp; Enjoy equal opportunities, and get a free Vendor assessment report.</p>
            
            <p><a href="/vendors-benefits" style="color: red">Find More Benefits..</a></p>
            <p style="align-items: center;"><a href="login/signup" ><button class="ovalbtn">Get Your Benefits</button></a> </p>
        </div>
      
    </div>
</div>
</section>



    <section class="sptb innerpage-margin sec7" style="background-color: #fff;">
        <h2 style="color: rgb(0, 0, 0);">Our Services/Products</h2> 
    <div class="container">
        <div class="row  why">
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black;align-content: center;">
                <p><img src="assets/images/media/home/services/mfg.webp" title="on demand manufacturing" alt="mfg" width="auto" height="auto"></p><p style="color: black;">On Demand Manufacturing</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                <p><img src="assets/images/media/home/services/poc.webp" title="poc" alt="poc" width="auto" height="auto"></p><p style="color: black;">Proof of Concept or Process</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                <p><img src="assets/images/media/home/services/quotation_service.webp" title="quotation service" alt="quotation service" width="auto" height="auto"></p><p style="color: black;">Quotation Service</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                <p><img src="assets/images/media/home/services/zbc.webp" title="zbc" alt="zbc" width="auto" height="auto"></p><p style="color: black;">Zero Based-Costing</p>
            </div>

        </div>
        <div class="row why" style="padding-top: 0%;">
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                <p><img src="assets/images/media/home/services/supplier_mgm.webp" title="supplier management" alt="supplier management" width="auto" height="auto"></p><p style="color: black;">Supplier Management</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                <p><img src="assets/images/media/home/services/rfq_ser.webp" title="RFQ" alt="RFQ" width="auto" height="auto"></p><p style="color: black;">RFQ Services</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                <p><img src="assets/images/media/home/services/resource_outsourcing.webp" title="Resource Outsourcing" alt="resource" width="auto" height="auto"></p><p style="color: black;">Resource Outsourcing</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                 <p><a href="/login/signup" ><img src="assets/images/media/home/services/starthere.webp" title="signup" alt="resource" width="auto" height="auto"></a></p>
            </div>

        </div>


   </div>

    </section>
	

    <section class="sptb innerpage-margin sec8" style="background-color: #fff">

        <div class="container">
            <div style="text-align: center;">
                    <h2>CUSTOMER SUCCESS STORIES </h2>
                    
                </div>
        </div>     
        <div class="wrapper">
            <div class="box">
            <i class="fa fa-quote-left quote"></i>
            <p>Your dedication and support have been instrumental in enabling us to secure new business opportunities worth 25 lakhs per month. We are grateful for your platform and the opportunities it has provided us. Your efforts inspire us to strive for greater success and we look forward to exploring more opportunities through <a href="">Getyourvendor.com </a>
                </p>
            <div class="content">
                <div class="info">
                <div class="name">Mr.Jagannath Ramdasi</div>
                <div class="job">Business Development Manager</div>
                <div class="job">Kirdak Autocom Pvt Ltd</div>
                <div class="stars">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                </div>
                </div>
                <div class="image">
                <img src="assets/images/media/home/review/ram.webp" alt="ram" width="100%" height="100%">
                </div>
            </div>
            </div>
            <div class="box">
            <i class="fa fa-quote-left quote"></i>
            <p>I am happy to register my company on <a href="">Getyourvendor.com </a> is a game-changer for suppliers. By registering my company on this platform, I was able to connect with hundreds of buyers from all over India. Thanks to their alert feature, I received a bulk order from a buyer within a month of registering. I couldn't be happier with my experience on GetYourVendor.com and strongly recommend it to all my fellow suppliers. Don't miss out on this opportunity to expand your business and grow your network.</p>
            <div class="content">
                <div class="info">
                <div class="name">Mr. Arvind Shete</div>
                <div class="job">Owner|MNS Engineers</div>
                <div class="stars">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                </div>
                </div>
                <div class="image">
                <img src="assets/images/media/home/review/arvind.webp" alt="arvid" width="100%" height="100%">
                </div>
            </div>
            </div>
            <div class="box">
            <i class="fa fa-quote-left  quote"></i>
            <p> We are thrilled to share some wonderful news with you. We have received our very first order through your portal. We deeply appreciate all the hard work and effort that the GYV team has put in. Your team has been helpful and professional from day one and we sincerely thank you for your support. The GYV portal has a lot of relevant RFQs from genuine buyers which is very helpful. We are excited about the possibility of establishing a long-term business relationship with you. 
            </p>
            <div class="content">
                <div class="info">
                <div class="name">Mr.Ankit Patel </div>
                <div class="job">Owner|Dimension Enterprise</div>
                <div class="stars">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                </div>
                </div>
                <div class="image">
                <img src="assets/images/media/home/review/ankitp.webp" alt="ankit" width="100%" height="100%">
                </div>
            </div>
            </div>
            <div class="container " style="padding-top:1%;">
                <p style="text-align: right; text-transform: uppercase;"><a href="/customerwins"><b>View More Success Stories <i class="fa fa-hand-o-right fa-lg" style="color: orange;"></i></b></a></p>
                
            </div>
        </div>
        

    </section>
    <section class="innerpage-margin faq" style="background-color: #fff;">
        <div class="container">
        <h2 style="margin-bottom:2%"> Frequently Asked Questions?</h2>
            <div aria-multiselectable="true" class="accordion1" id="accordion" role="tablist">
                 <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse1">
                        1. Who qualifies to become a vendor or supplier?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse1" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;" >
                            Companies that engage in custom-manufacturing activities and are registered under the government-authorized industry laws of their respective countries are eligible to become vendors or suppliers.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse2">
                        2. Who qualifies to become a buyer?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse2" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Any company interested in outsourcing manufacturing activities may become a buyer.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse3">
                        3. What are the charges associated with this platform?
                    </div>
                    <div  class="collapse" data-parent="#accordion" id="collapse3" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            The subscription packages for this platform start at approximately 60 INR per day. For more information on the different packages available, checkout <a href="./newpricingpage"target="_blank" >Vendor Packages</a>.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse4">
                        4.  What assurance does the platform offer for business success?
                    </div>
                    <div  class="collapse" data-parent="#accordion" id="collapse4" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our short-term subscriber conversion rate is around 50%, while our long-term subscriber conversion rate is an impressive 99%. Additionally, our tailored packages come with a money-back guarantee.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse5">
                        5. How often do new businesses (RFQ) appear on the platform?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse5" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our database is updated with fresh business opportunities every Tuesday and Friday of each week.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse6">
                        6. How many registrations are allowed under a single company name?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse6" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our Gold package allows for multiple registrations under one company name. Users can be added to a package by paying nominal user fees.
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse7">
                       7. How do I contact the buyer for further communication once a new RFQ is found?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse7" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our technology-driven platform assists you in securing orders once you identify a suitable RFQ.
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse8">
                        8. Is my data secure on the platform?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse8" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Your data, including drawings, quotations, and profile information, is safeguarded by our advanced security measures and digital non-disclosure agreement features.
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse9">
                        9. What types of buyers are using the platform?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse9" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our buyers come from a variety of sectors including Automotive, Engineering, Aerospace, Shipbuilding, Machine Manufacturing, Oil and Gas, Construction, Railways, Chemicals, Electronics, Electrical, 3D Printing, and Pharmaceutical industries.
                        </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse10">
                        10. How can I register my company on the platform?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse10" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Click on the <a href="/login/signup"> link </a> to initiate a lasting partnership with GetYourVendor.
                        </div>
                    </div>
                </div>
                
            </div><!-- accordion -->
        </div>
 
        <div class="container"> 
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <a href="login/signup" > <img src=
                "assets/images/media/vendorbtn.webp"
                alt="vendor" width="auto" height="auto" title="are you vendor?"  > </a>
    
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <a href="login/signup" ><img src=
              "assets/images/media/buyerbtn.webp"
              alt="buyer" width="auto" height="auto" title="are you buyer?" > </a>
            </div>
          </div>
      
        </div>
      
      </section>
    <login-home-footer></login-home-footer>