import { Component, OnInit } from '@angular/core';
import { GlobalComponent } from 'src/app/global/global.component';
import { SeoService } from 'src/app/service/seo.service';

@Component({
  selector: 'app-customerwins',
  templateUrl: './customerwins.component.html',
  styleUrls: ['./customerwins.component.scss']
})
export class CustomerwinsComponent implements OnInit {

  hostName = GlobalComponent.hostName;

  constructor(private SEOService:SeoService) { }


  ngOnInit(): void {
    window.scrollTo(0, 0);
  this.SEOService.updateCanonicalUrl(this.hostName + '/customerwins');
}
scroll(el: HTMLElement){
  el.scrollIntoView({behavior: 'smooth'})
}

}
