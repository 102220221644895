<div>
    <login-home-navigation></login-home-navigation>
	<p>&nbsp;</p>
</div>
<section class="sptb innerpage-margin" style="background-color:#fff;" >
    <div class="container">
        <h2>Experience the winning journey with us....here are our customers satisfaction board.</h2>
        <div class="row">   
          <div class="col-lg-2 col-md-2 col-sm-2">

    <a target="_blank" href="./../../assets/images/media/home\customerwins\2.webp">
        
    <img src="../../../assets/images/media/home\customerwins\2.webp" alt="customerwins" style="width:150px">
    </a>
          </div>
           <div class="col-lg-2 col-md-2 col-sm-2">
    <a target="_blank" href="./../../assets/images/media/home\customerwins\3.webp">
        
        <img src="../../../assets/images/media/home\customerwins\3.webp" alt="customerwins" style="width:150px">
    </a>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-2">
             <a target="_blank" href="./../../assets/images/media/home\customerwins\4.webp">
                
                <img src="../../../assets/images/media/home\customerwins\4.webp" alt="customerwins" style="width:150px">
             </a>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
                <a target="_blank" href="./../../assets/images/media/home\customerwins\5.webp">
                   
                   <img src="../../../assets/images/media/home\customerwins\5.webp" alt="customerwins" style="width:150px">
                </a>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
                <a target="_blank" href="./../../assets/images/media/home\customerwins\6.webp">
                   
                   <img src="../../../assets/images/media/home\customerwins\6.webp" alt="customerwins" style="width:150px">
                </a>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
                <a target="_blank" href="./../../assets/images/media/home\customerwins\7.webp">
                   
                   <img src="../../../assets/images/media/home\customerwins\7.webp" alt="customerwins" style="width:150px">
                </a>
            </div>
        </div>
    </div>
</section>
<section class="sptb innerpage-margin" style="background-color:#fff;" >
    <div class="container">
        <div class="row">   
          <div class="col-lg-2 col-md-2 col-sm-2">

    <a target="_blank" href="./../../assets/images/media/home\customerwins\8.webp">
        
    <img src="../../../assets/images/media/home\customerwins\8.webp" alt="customerwins" style="width:150px">
    </a>
          </div>
           <div class="col-lg-2 col-md-2 col-sm-2">
    <a target="_blank" href="./../../assets/images/media/home\customerwins\17.webp">
        
        <img src="../../../assets/images/media/home\customerwins\17.webp" alt="customerwins" style="width:150px">
    </a>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-2">
             <a target="_blank" href="./../../assets/images/media/home\customerwins\13.webp" title="customer parts" >
                
                <img src="../../../assets/images/media/home\customerwins\13.webp" alt="customerwins" style="width:150px" title="customer parts">
             </a>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
                <a target="_blank" href="./../../assets/images/media/home\customerwins\14.webp">
                   
                   <img src="../../../assets/images/media/home\customerwins\14.webp" alt="customerwins" style="width:150px">
                </a>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
                <a target="_blank" href="./../../assets/images/media/home\customerwins\15.webp">
                   
                   <img src="../../../assets/images/media/home\customerwins\15.webp" alt="customerwins" style="width:150px">
                </a>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
                <a target="_blank" href="./../../assets/images/media/home\customerwins\16.webp">
                   
                   <img src="../../../assets/images/media/home\customerwins\16.webp" alt="customerwins" style="width:150px">
                </a>
            </div>
        </div>
    </div>
</section>
<section class="sptb innerpage-margin" style="background-color:#fff;" >
    <div class="container">
        <div class="row">   
          <div class="col-lg-2 col-md-2 col-sm-2">

    <a target="_blank" href="./../../assets/images/media/home\customerwins\18.webp">
        
    <img src="../../../assets/images/media/home\customerwins\18.webp" alt="customerwins" style="width:150px">
    </a>
          </div>
           <div class="col-lg-2 col-md-2 col-sm-2">
    <a target="_blank" href="./../../assets/images/media/home\customerwins\20.webp">
        
        <img src="../../../assets/images/media/home\customerwins\20.webp" alt="customerwins" style="width:150px">
    </a>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-2">
             <a target="_blank" href="./../../assets/images/media/home\customerwins\19.webp" title="customer parts" >
                
                <img src="../../../assets/images/media/home\customerwins\19.webp" alt="customerwins" style="width:150px" title="customer parts">
             </a>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
                <a target="_blank" href="./../../assets/images/media/home\customerwins\21.webp">
                   
                   <img src="../../../assets/images/media/home\customerwins\21.webp" alt="customerwins" style="width:150px">
                </a>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
                <a target="_blank" href="./../../assets/images/media/home\customerwins\23.webp">
                   
                   <img src="../../../assets/images/media/home\customerwins\23.webp" alt="customerwins" style="width:150px">
                </a>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
                <a target="_blank" href="./../../assets/images/media/home\customerwins\22.webp">
                   
                   <img src="../../../assets/images/media/home\customerwins\22.webp" alt="customerwins" style="width:150px">
                </a>
            </div>
        </div>
    </div>
</section>
<section class="sptb innerpage-margin" style="background-color:#fff;" >
    <div class="container">
        <div class="row">   
          <div class="col-lg-2 col-md-2 col-sm-2">

    <a target="_blank" href="./../../assets/images/media/home\customerwins\24.webp">
        
    <img src="../../../assets/images/media/home\customerwins\24.webp" alt="customerwins" style="width:150px">
    </a>
          </div>
           <div class="col-lg-2 col-md-2 col-sm-2">
    <a target="_blank" href="./../../assets/images/media/home\customerwins\25.webp">
        
        <img src="../../../assets/images/media/home\customerwins\25.webp" alt="customerwins" style="width:150px">
    </a>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-2">
             <a target="_blank" href="./../../assets/images/media/home\customerwins\11.webp" title="customer parts" >
                
                <img src="../../../assets/images/media/home\customerwins\11.webp" alt="customerwins" style="width:150px" title="customer parts">
             </a>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
                <a target="_blank" href="./../../assets/images/media/home\customerwins\12.webp">
                   
                   <img src="../../../assets/images/media/home\customerwins\12.webp" alt="customerwins" style="width:150px">
                </a>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
                <a target="_blank" href="./../../assets/images/media/home\customerwins\26.webp">
                   
                   <img src="../../../assets/images/media/home\customerwins\26.webp" alt="customerwins" style="width:150px">
                </a>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2">
                <a target="_blank" href="./../../assets/images/media/home\customerwins\27.webp">
                   
                   <img src="../../../assets/images/media/home\customerwins\27.webp" alt="customerwins" style="width:150px">
                </a>
            </div>
        </div>
    </div>
    <div class="container " style="padding-top:1%;">
        <p style="text-align: right; text-transform: uppercase;"><a href="/testimonials"><b>View Testimonials <i class="fa fa-hand-o-right fa-lg" style="color: orange;"></i></b></a></p>
        
    </div>
    <div class="container">
    <div class="row" style="padding-top:1%;">
        <div class="col-lg-6 col-md-6 col-sm-6 center" >
            <a href="login/signup"> <button style="color: white; background-color: #e24b4b;">Join as a Vendor</button></a>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 center">
          <a href="login/signup"> <button style="color: white; background-color: #e24b4b;">Join as a Buyer</button></a>
        </div>
        
    </div>
</div>
</section>

<login-home-footer></login-home-footer>