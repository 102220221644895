import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
//import { NotFoundComponent } from '../shared/not-found/not-found.component';
//import { HomeNewComponent } from '../home/home-new/home-new.component';
import { HomeComponent } from './home.component';
import { HomeNavigationComponent } from './home-navigation/home-navigation.component';
import { HomeFooterComponent } from './home-footer/home-footer.component';
import { LeadDialog } from './home-footer/home-footer.component';
//import { HomeTestimonialsComponent } from './home-testimonials/home-testimonials.component';
import { SignupComponent } from '../home/signup/signup.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { MatCarouselModule } from '@ngbmodule/material-carousel';
//import { MatStepperModule } from '@angular/material/stepper';
//import {MatSelectModule} from '@angular/material/select';
//import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import { GyvMartdlgComponent } from './home-navigation/home-navigation.component';
import { LandingpagesComponent } from './landingpages/landingpages.component';
//import { EmbedVideo } from 'ngx-embed-video';
import {MatTabsModule} from '@angular/material/tabs';
import { DemoComponent } from './landingpages/landingpages.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { StrategicpartnerComponent } from './strategicpartner/strategicpartner.component';
import { BiddingSignupComponent } from './bidding-signup/bidding-signup.component';
import { MatCardModule } from '@angular/material/card';
import { CustomerwinsComponent } from './customerwins/customerwins.component';

//import Swal from 'sweetalert2';

@NgModule({
  declarations: [
  //  NotFoundComponent,        
    HomeComponent,
    HomeNavigationComponent,
    HomeFooterComponent,
    LeadDialog,
    //HomeTestimonialsComponent,
    //HomeNewComponent,
    //SignupComponent,
    GyvMartdlgComponent,
    LandingpagesComponent,
    DemoComponent,
    TestimonialsComponent,
    StrategicpartnerComponent,
    BiddingSignupComponent,
    CustomerwinsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TypeaheadModule.forRoot(),
    //MatCarouselModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
   // MatStepperModule,
    //MatSelectModule,
    //MatCheckboxModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
   // EmbedVideo
    
  ],
  exports:[
    RouterModule,
    HomeComponent,
    HomeNavigationComponent,
    HomeFooterComponent
   
  ]
})
export class HomeModule { }
