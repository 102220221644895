import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.scss']
})
export class GlobalComponent implements OnInit {

//public static appsrcURL: string = "https://developmentapi.getyourvendor.com/public";
//public static appsrcURL: string = "https://apiv2.getyourvendor.com/public";
public static appsrcURL: string = "https://developmentapiv2.getyourvendor.com/public";

// public static appsrcURL: string = "https://app.getyourvendor.com/public";
//public static hostName: string ="https://getyourvendor.com";
public static hostName: string ="https://production.getyourvendor.com";
//public static hostName: string ="localhost:4200";


  constructor() { }

  ngOnInit(): void {
  }

}